.LogoIcon {
    background-color: #2F80ED;
    padding: 0em;
    height: 2.75em;
    width: 2.75em;
    border-radius: 50%;

}
.LogoIcon img{
    margin: 20%;
    width: 60%;
}