.Media{
    height: 11.25rem;
    background-size: cover;
    background-position: bottom;
    z-index: 1;
}
.HoverCard{
    height: 11.25rem;
    width: 100%;
    margin-top: -11.25rem;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(0,0,0, 0.2);
}
.TopRow {
    display: flex;
}
.Stars{
    margin: 0.75rem auto auto 0.5rem;
    display: inline;
}
.Heart {
    margin: 0 !important;
    display: inline;
}
.Price{
    padding-left: 0.5rem;
}
:global .PrimaryCard{
    background-color: #2F80ED;
    color: white;
}
@media (max-width: 600px) { 
    .Heart{
        position: static;
    }
}