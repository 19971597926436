.Hero{
    background-image: url("../../../../assets/hero-background.jpeg");
    background-position: center;
    background-size: cover;
    background-color: #2F80ED;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.HeroContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.HeroCard{
    max-width: 20em;
}
.HeroCard h3{
    margin-bottom: 1rem;
}
.Media{
    height: 15em;
}



@media (max-width: 600px) { 
    .Hero{
        min-height: 95vh;
    }
    .Hero h1{
        font-size: 3rem;
        line-height: 1.2;
    }
    .Hero h6{
        font-size: 1.5em;
        line-height: 1.2;
    }
}
