.Logo {
    color: #fff;
    display: inline-flex;
    align-items: center;
}
.Logo span {
    display: inline;
    margin-left: 0.5em;
    line-height: 2.5rem;
    font-size: 1.62em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}