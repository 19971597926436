.Innernav{
    display: flex;
    justify-content: space-between;
    padding: 3.75em 0;
}

.navElements{
    display: flex;
}

@media (max-width: 600px) { 
    .Innernav{
        padding: 2em 0 1em 0;
    }
}