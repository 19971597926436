@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap);
html, body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Nunito', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.LogoIcon_LogoIcon__H3GWW {
    background-color: #2F80ED;
    padding: 0em;
    height: 2.75em;
    width: 2.75em;
    border-radius: 50%;

}
.LogoIcon_LogoIcon__H3GWW img{
    margin: 20%;
    width: 60%;
}
.Logo_Logo__KGBh8 {
    color: #fff;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
}
.Logo_Logo__KGBh8 span {
    display: inline;
    margin-left: 0.5em;
    line-height: 2.5rem;
    font-size: 1.62em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
.Nav_Innernav__1s6Ry{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 3.75em 0;
}

.Nav_navElements__2mbL8{
    display: -webkit-flex;
    display: flex;
}

@media (max-width: 600px) { 
    .Nav_Innernav__1s6Ry{
        padding: 2em 0 1em 0;
    }
}
.Waves_Wave__8OwX8 {
    width: 105%;
    margin-bottom: -1px;
    overflow: hidden;
} 
.Hero_Hero__Imt7y{
    background-image: url(/static/media/hero-background.c579ce3d.jpeg);
    background-position: center;
    background-size: cover;
    background-color: #2F80ED;
    min-height: 70vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.Hero_HeroContent__1Dlbv{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.Hero_HeroCard__vcGP8{
    max-width: 20em;
}
.Hero_HeroCard__vcGP8 h3{
    margin-bottom: 1rem;
}
.Hero_Media__3UJ_m{
    height: 15em;
}



@media (max-width: 600px) { 
    .Hero_Hero__Imt7y{
        min-height: 95vh;
    }
    .Hero_Hero__Imt7y h1{
        font-size: 3rem;
        line-height: 1.2;
    }
    .Hero_Hero__Imt7y h6{
        font-size: 1.5em;
        line-height: 1.2;
    }
}

.Footer_Footer__1n4gD{
    padding: 1rem;
}
.HeartIcon_HeartIcon__3Bg-T:hover{
    cursor: pointer;
}
.Stars_Stars__3hbtM{
    
}
.Stars_Star__5ymo0{
}
.Price_Price__37kfE{
    font-size: 1.5em;
    font-weight: 800;
    font-size: '1.5em';
    color: white;
    letter-spacing: 1.2px;
}
.BoatCard_Media__11Haj{
    height: 11.25rem;
    background-size: cover;
    background-position: bottom;
    z-index: 1;
}
.BoatCard_HoverCard__2sPdT{
    height: 11.25rem;
    width: 100%;
    margin-top: -11.25rem;
    z-index: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background-color: rgba(0,0,0, 0.2);
}
.BoatCard_TopRow__1i3z6 {
    display: -webkit-flex;
    display: flex;
}
.BoatCard_Stars__1HFIQ{
    margin: 0.75rem auto auto 0.5rem;
    display: inline;
}
.BoatCard_Heart__2vblW {
    margin: 0 !important;
    display: inline;
}
.BoatCard_Price__XvxIM{
    padding-left: 0.5rem;
}
.PrimaryCard{
    background-color: #2F80ED;
    color: white;
}
@media (max-width: 600px) { 
    .BoatCard_Heart__2vblW{
        position: static;
    }
}
.BoatGrid_Grid__-VNXK{
    padding: 2.5em 0
}
.AdsCard_Card__1qyAI{
    text-align: center;
    -webkit-align-items: stretch;
            align-items: stretch;
}
.AdsCard_Card__1qyAI .AdsCard_Icon__1WCj2{
    width: 3em;
    height: 3em;
}
.AdsCard_Card__1qyAI h2{
    margin-bottom: 0.75em;
    padding: 0 1em;
    font-weight: 700;
}
.AdsSection_Section__1cPNq div{
}
.AdsSection_Section__1cPNq h2{
    font-weight: 700;
}
.ButtonIcon_Icon__3-lr- {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    font-size: 1rem ;
    margin-left: 0.5em;
}
.Home_Container__2NLmM{
    padding-top: 3rem;
    padding-bottom: 1rem;
}
