.Card{
    text-align: center;
    align-items: stretch;
}
.Card .Icon{
    width: 3em;
    height: 3em;
}
.Card h2{
    margin-bottom: 0.75em;
    padding: 0 1em;
    font-weight: 700;
}